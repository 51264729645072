<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Broadcast</span>
        </div>
      </div>

      <div class="hcard relative z-10 p-7">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-5 mt-2 animate-pulse"
          v-if="loading"
        >
          <div class="relative" v-for="b in 4" :key="b">
            <div class="hcard-b17 flex flex-col p-5 h-full">
              <div class="flex-grow">
                <div class="grid-cols-1 mb-7">
                  <div class="h-6 w-32 bg-hline rounded-lg"></div>

                  <div class="h-0.5 w-full my-3 bg-hline rounded-lg"></div>

                  <div class="h-5 w-68 bg-hline rounded-lg"></div>
                  <div class="h-5 w-56 mt-1 bg-hline rounded-lg"></div>
                  <div class="h-5 w-60 mt-1 bg-hline rounded-lg"></div>
                  <div class="h-5 w-28 mt-1 bg-hline rounded-lg"></div>
                  <div class="h-5 w-38 mt-1 bg-hline rounded-lg"></div>
                </div>
              </div>
              <div class="flex justify-between">
                <div class="">
                  <div class="h-7 w-24 bg-hline rounded-lg"></div>
                </div>
                <div class="relative">
                  <div class="h-5 w-28 bg-hline rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-5 mt-2" v-else>
          <div class="col-span-2 py-24" v-if="!broadcasts.data.length">
            <img :src="NoData" class="mx-auto" />
            <div class="capitalize font-semibold text-5xl text-hblue text-center">
              no broadcast yet!
            </div>
          </div>

          <div class="relative" v-for="b in broadcasts.data" :key="b.id">
            <div class="hcard-b17 flex flex-col p-5 h-full">
              <div class="flex-grow">
                <div class="grid-cols-1 mb-7">
                  <div class="font-semibold text-black text-sm">{{ b.title }}</div>

                  <div class="my-3" style="border-bottom: 1px solid #bfbfbf"></div>
                  <div class="text-black text-sm line-clamp-6">{{ b.message }}</div>
                </div>
              </div>
              <div class="flex justify-between">
                <div class="">
                  <button
                    class="btn btn-xs capitalize btn-primary w-28 border-0 hover:bg-hblue"
                    v-if="b.message.length > 310"
                    @click="toDetail(b)"
                  >
                    Read More
                  </button>
                </div>
                <div class="relative">
                  <div class="text-sm font-medium">
                    {{ b.published_at }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import NoData from '@/assets/images/icon-noMessage.png'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'broadcasts',
  components: {
    IconArrow
  },
  data() {
    return {
      NoData,
      loading: true
    }
  },
  computed: {
    ...mapState({
      broadcasts: state => state.notif.broadcast
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('notif', ['GET_BROADCASTS', 'SET_BROADCAST']),
    async toDetail(val) {
      let removeSpacing = val.title ? val.title.replaceAll(/\s+/g, '-') : ''
      let removeSlash = removeSpacing.replaceAll('/', '')

      await this.SET_BROADCAST(val)
      this.$router.push({ name: 'broadcast.detail', params: { slug: removeSlash } })
    },
    async getData() {
      this.loading = true
      await this.GET_BROADCASTS()

      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>
